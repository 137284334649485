@font-face {
  font-family: 'Tahoma';
  src: url(/static/media/Tahoma.0396bdb7.woff2) format('woff2'),
      url(/static/media/Tahoma.ca069b0b.woff) format('woff'),
      url(/static/media/Tahoma.4b2b5330.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tahoma-Bold';
  src: url(/static/media/Tahoma-Bold.b23ebb40.woff2) format('woff2'),
      url(/static/media/Tahoma-Bold.ca37df70.woff) format('woff'),
      url(/static/media/Tahoma-Bold.c1126e19.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CourierPrime';
  src: url(/static/media/CourierPrime-Regular.68d89efc.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CourierBold';
  src: url(/static/media/CourierPrime-Bold.c71ba360.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

