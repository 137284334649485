@font-face {
  font-family: 'Tahoma';
  src: url(../assets/fonts/Tahoma.woff2) format('woff2'),
      url(../assets/fonts/Tahoma.woff) format('woff'),
      url(../assets/fonts/Tahoma.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tahoma-Bold';
  src: url(../assets/fonts/Tahoma-Bold.woff2) format('woff2'),
      url(../assets/fonts/Tahoma-Bold.woff) format('woff'),
      url(../assets/fonts/Tahoma-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CourierPrime';
  src: url(../assets/fonts/CourierPrime-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CourierBold';
  src: url(../assets/fonts/CourierPrime-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
